<template>
  <div class="modal-card" style="width: auto">
    <header class="modal-card-head">
        <p class="modal-card-title"> {{ titulo }} </p>
    </header>
    <section class="modal-card-body">
      <div v-html="mensaje"></div>
    </section>
    <footer class="modal-card-foot">
      <button class="button is-success" type="button" @click="$emit('closeMessage',true, accion)">Si</button>
      <button class="button is-danger" type="button" @click="$emit('closeMessage',false, accion)">No</button>
    </footer>
  </div>
</template>
<script>
export default {
  name: 'WMessageModal',
  props: {
    mensaje: String,
    titulo: String,
    accion: Object
  }
}
</script>
